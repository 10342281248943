import React, { useState, useEffect } from 'react';
import { 
  Briefcase, 
  Mail, 
  Phone, 
  Award, 
  Terminal, 
  ChevronRight,
  Github, 
  Linkedin,
  Code,
  Server,
  Cloud,
  Shield,
  User,
  MapPin,
  Calendar,
  Network,
  ClipboardList,
  BarChart,
  SaveAll,
  Layout,
  Menu,
  X
} from 'lucide-react';


// Add these interfaces
interface SidebarButtonProps {
    icon: React.ElementType;
    title: string;
    isActive: boolean;
    onClick: () => void;
  }
  
  interface SkillCardProps {
    icon: React.ElementType;
    title: string;
    skills: string[];
  }
  
  interface ExperienceCardProps {
    role: string;
    company: string;
    period: string;
    location?: string;
    achievements: string[];
  }
  
  interface CertificationCardProps {
    title: string;
    id?: string;
    issuer: string;
    date?: string;
  }

  

  const SidebarButton: React.FC<SidebarButtonProps> = ({ icon: Icon, title, isActive, onClick }) => (
    <button
    onClick={onClick}
    className={`w-full flex items-center p-4 transition-all duration-300 relative ${
      isActive 
        ? 'bg-gradient-to-r from-orange-500 to-amber-500 text-white font-semibold shadow-lg' 
        : 'text-slate-300 hover:bg-slate-700'
    }`}
  >
    <Icon className={`w-5 h-5 mr-3 ${isActive ? 'text-white' : 'text-orange-400'}`} />
    <span className="font-medium">{title}</span>
    {isActive && (
      <div className="absolute right-2">
        <ChevronRight className="w-5 h-5 text-white" />
      </div>
    )}
  </button>
);

const SkillCard: React.FC<SkillCardProps> = ({ icon: Icon, title, skills }) => (
    <div className="group relative bg-white p-6 rounded-xl border border-slate-200 hover:border-sky-500 transition-all duration-300">
      <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-sky-400 via-indigo-500 to-purple-600 rounded-l-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      <div className="relative">
        <div className="flex items-center mb-4">
          <Icon className="w-6 h-6 text-sky-500 mr-3" />
          <h3 className="text-lg font-semibold text-slate-800">{title}</h3>
        </div>
        <div className="flex flex-wrap gap-2">
          {skills.map((skill) => (
            <span 
              key={skill}
              className="px-3 py-1.5 bg-sky-50 text-sky-700 rounded-lg text-sm font-medium 
                hover:bg-sky-100 hover:text-sky-800 transition-colors duration-300 border border-sky-100"
            >
              {skill}
            </span>
          ))}
        </div>
      </div>
    </div>
  );

const ExperienceCard: React.FC<ExperienceCardProps> = ({ role, company, period, location, achievements }) => (
    <div className="group relative bg-white p-6 rounded-xl border border-slate-200 hover:border-sky-500 transition-all duration-300">
    <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-sky-400 via-indigo-500 to-purple-600 rounded-l-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
    
    <h3 className="text-lg font-semibold text-slate-800 mb-4">{role}</h3>
    <div className="flex flex-wrap gap-4 mb-4 text-sm">
    <div className="flex items-center text-slate-600">
        <Briefcase className="w-4 h-4 mr-2 text-sky-500" />
        {company}
      </div>
      <div className="flex items-center text-slate-600">
        <Calendar className="w-4 h-4 mr-2 text-sky-500" />
        {period}
      </div>
      {location && (
        <div className="flex items-center text-slate-600">
          <MapPin className="w-4 h-4 mr-2 text-sky-500" />
          {location}
        </div>
      )}
    </div>
    
    <ul className="space-y-2">
      {achievements.map((achievement, index) => (
        <li key={index} className="flex items-start">
          <ChevronRight className="w-4 h-4 mr-3 mt-1 flex-shrink-0 text-sky-500" />
          <span className="text-slate-600">
            {achievement}
          </span>
        </li>
      ))}
    </ul>
  </div>
);

const CertificationCard: React.FC<CertificationCardProps> = ({ title, id, issuer, date }) => (
    <div className="group relative bg-white p-6 rounded-xl border border-slate-200 hover:border-sky-500 transition-all duration-300">
   <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-sky-400 via-indigo-500 to-purple-600 rounded-l-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
    <div className="flex items-start justify-between">
      <div>
      <h3 className="text-lg font-semibold text-slate-800 mb-4">{title}</h3>
      <div className="text-slate-600 text-sm">{issuer}</div>

        {id && <div className="text-slate-600 text-sm">ID: {id}</div>}
      </div>
      <Award className="w-6 h-6 text-sky-500" />
    </div>
    {date && (
      <div className="mt-4 text-sm flex items-center text-sky-500">
        <Calendar className="w-4 h-4 mr-2" />
        {date}
      </div>
    )}
  </div>
);

const ResumeSite = () => {
  const [activeSection, setActiveSection] = useState('about');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  const sections = [
    { id: 'about', title: 'About Me', icon: User },
    { id: 'experience', title: 'Experience', icon: Briefcase },
    { id: 'skills', title: 'Skills', icon: Terminal },
    { id: 'certifications', title: 'Certifications', icon: Award },
  ];

  // Add useEffect to update document title when section changes
  useEffect(() => {
    const activeTab = sections.find(section => section.id === activeSection);
    document.title = `Tyrone Borg - ${activeTab?.title || 'IT Manager'}`;
    }, [activeSection]);

    const handleSectionClick = (sectionId: string) => {
      setActiveSection(sectionId);
      setIsSidebarOpen(false);
    };


  const renderContent = () => {
    switch (activeSection) {
      case 'about':
        return (
          <div className="space-y-6">
            <div className="group relative bg-white p-6 rounded-xl border border-slate-200 hover:border-sky-500 transition-all duration-300">
              <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-sky-400 via-indigo-500 to-purple-600 rounded-l-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <h2 className="text-2xl font-bold text-slate-800 mb-6">Tyrone Borg</h2>
              <p className="text-lg text-slate-600 mb-4">
                IT Manager & Systems Administrator with extensive experience in managing IT operations
                across international offices. Focused on infrastructure management, security implementation, service desk
                and process automation.
              </p>
              
              <div className="grid md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <h3 className="font-semibold text-slate-700 mb-4">Contact Information</h3>
                  <a href="mailto:tyrone@tyrborg.com" 
                    className="flex items-center text-slate-600 hover:text-sky-600 transition-colors duration-300">
                    <Mail className="w-5 h-5 mr-3 text-sky-500" />
                    tyrone@tyrborg.com
                  </a>
                  <div className="flex items-center text-slate-600">
                    <Phone className="w-5 h-5 mr-3 text-sky-500" />
                    +44 7716218767
                  </div>
                  <div className="flex items-center text-slate-600">
                    <MapPin className="w-5 h-5 mr-3 text-sky-500" />
                    Luxembourg 
                  </div>
                </div>
                
                <div className="space-y-4">
                  <h3 className="font-semibold text-slate-700 mb-4">Professional Profiles</h3>
                  <div className="flex gap-3">
                    <a href="https://www.linkedin.com/in/tyroneborg/" className="p-3 rounded-lg bg-gradient-to-r from-sky-50 to-indigo-50 hover:from-sky-100 hover:to-indigo-100 transition-colors duration-300">
                      <Linkedin className="w-6 h-6 text-slate-600 hover:text-indigo-600" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 'experience':
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-slate-800 mb-6">Professional Experience</h2>
            <ExperienceCard
              role="IT Support & Service Desk Manager"
              company="Byborg Enterprises"
              period="Nov 2020 – Present"
              location="Luxembourg"
              achievements={[
                "Responsible for the IT Operations team in Luxembourg and Los Angeles consisting of eight team members.",
                "Mentoring the team members and providing guidance when it is needed.",
                "Providing consistently high level of IT Support across both locations while ensuring proper SLA is met.",
                "Successfully lead the implementation of multiple projects, which include a centralised monitoring system using Grafana and Zabbix, merging of two separate Snipe-IT instances into one single central system, MDM implementations for endpoint devices (Intune, Mosyle,Landscape) and many more.",
                "Supervised the administration of the office infrastructure including Active Directory, Azure AD,OpenVPN, DHCP, DNS, MFA, Microsoft 365 products and more.",
                "Managed the configuration of the endpoints including Windows, MacOS & Ubuntu ensuring all the standard security configuration is present on all machines.",
                "Successfully automated various tasks, such as employee onboarding & offboarding, backup procedures for servers, using scripting with PowerShell or bash.",
                "Proactively monitored infrastructure health and addressing alerts promptly",
                "Maintained up to date documentation and created SOPs when necessary.",
                "Kept track of the hardware inventory using Snipe-IT.",
                "Liaised with external suppliers and internal departments.",
                "Participating in an on-call rotation to provide support after office hours."
              ]}
            />
            <ExperienceCard
              role="IT Systems Administrator"
              company="iGaming Group"
              period="Jul 2020 – Oct 2020"
              location="Malta"
              achievements={[
                "Successfully implemented Microsoft 365 Azure AD and Intune from scratch.",
                "Enforced IT security policies such as removing administrator permissions for all users and setting up an anti-virus protection to protect the company data.",
                "Supported end users with technical queries across multiple departments",
                "Replacing end of life equipment to improve the office infrastructure, such as Printers, access points and routers."
              ]}
            />

            <ExperienceCard
              role="Systems Administrator"
              company="Ascent Software"
              period="Feb 2020 – Jul 2020"
              location="Malta"
              achievements={[
                "Responsible for providing IT support.",
                "Managed Backups using Veeam, checking status of backups and testing restore options.",
                "Implemented Zabbix server to monitor the infrastructure."
             ]}
            />
            <ExperienceCard
              role="Systems Administrator"
              company="HID Global"
              period="Aug 2019 – Jan 2020"
              location="Malta"
              achievements={[
                "Implemented a new system for the e-ID and passport services of the Maltese Government.",
                "Providing IT support to end users at the passport office of Malta.",
                "Implemented SCCM server for manging Windows 10 devices."
             ]}
            />
            <ExperienceCard
              role="Systems Engineer"
              company="Melita"
              period="Jan 2018 – Sep 2019"
              location="Malta"
              achievements={[
                "Managed DNS, DHCP and email servers for the customers.",
                "Successfully implemented new servers such as DNS, DHCP and speedtest to establish a new operational hub for the services in Italy. ",
                "Created various bash scripts to automate repetitive tasks.",
                "Lead the testing of various access point solutions that were later sold to our customers."
             ]}
            />
          </div>
        );

        case 'skills':
            return (
              <div className="space-y-6">
                <h2 className="text-2xl font-bold text-slate-800 mb-6">Technical Expertise</h2>
                <div className="grid md:grid-cols-2 gap-6">

                  <SkillCard
                    icon={Cloud}
                    title="Cloud & Virtualization"
                    skills={['Azure', 'AWS', 'Hyper-V', 'Docker', 'VMware', 'Proxmox']}
                  />
                                  <SkillCard
                    icon={BarChart}  // Import this icon
                    title="Monitoring & Analytics"
                    skills={[
                        'Grafana',
                        'Zabbix',
                        'Nagios',
                        'Prometheus',
                        'SolarWinds',
                        'Power Bi'
                    ]}
                    />
                  <SkillCard
                    icon={Code}
                    title="Automation & Scripting"
                    skills={['PowerShell', 'Bash', 'Python', 'Ansible', 'Terraform', 'Git']}
                  />
                  <SkillCard
                    icon={Shield}
                    title="Security & Management"
                    skills={['Intune', 'Jamf', 'Mosyle', 'Active Directory', 'Azure AD' , 'Microsoft Defender', 'Sentinel One', 'GPO']}
                  />
                    <SkillCard
                    icon={Network}
                    title="Networking"
                    skills={['DHCP', 'DNS', 'Vlans', 'Firewall', 'Cisco IOS']}
                  />

                   <SkillCard
                    icon={SaveAll}  // Import this icon
                    title="Backup & Disaster Recovery"
                    skills={[
                        'Veeam',
                        'Acronis',
                        'Azure Backups'
                    ]}
                    />
                                      <SkillCard
                    icon={Server}
                    title="Operating Systems"
                    skills={['Windows', 'MacOS', 'Ubuntu', 'Red Hat']}
                  />


                 <SkillCard
                    icon={ClipboardList}
                    title="ITSM"
                    skills={['Jira', 'Confluence', 'Freshdesk']}
                  />
                </div>
              </div>
            );

      case 'certifications':
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-slate-800 mb-6">Certifications</h2>
            <div className="grid md:grid-cols-2 gap-6">
              <CertificationCard
                title="Microsoft Azure Administrator Associate"
                id="BBD242-X5F2D6"
                issuer="Microsoft"
              />
              <CertificationCard
                title="Microsoft 365 Certified Endpoint Administrator"
                id="348D41-0DA1AH"
                issuer="Microsoft"
              />
              <CertificationCard
                title="ITIL Foundation Certificate"
                id="GR671539756TB"
                issuer="AXELOS Global Best Practice"
              />
              <CertificationCard
                title="Cisco CCST"
                issuer="Cisco"
              />
             <CertificationCard
                title="Jamf 100"
                id="jfv94szoooau"
                issuer="Jamf"
              />

            <CertificationCard
                title="Bsc. Computer Networks & systems "
                issuer="MCAST"

              />
              
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-orange-50">
      {/* Mobile Menu Button */}
      <button 
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="fixed top-4 left-4 z-50 p-2 rounded-lg bg-slate-800 text-white md:hidden"
      >
        {isSidebarOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>
  
      {/* Overlay for mobile */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
  
      {/* Sidebar */}
      <div className={`fixed w-64 h-screen bg-gradient-to-b from-slate-900 to-slate-800 overflow-y-auto z-40 transition-transform duration-300 ease-in-out md:translate-x-0 ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}>
        <div className="p-6 border-b border-slate-700">
          <h1 className="text-2xl font-bold text-orange-50">Tyrone Borg</h1>
          <p className="text-amber-500">IT Manager / Systems Administrator</p>
        </div>
        <nav className="py-4">
          {sections.map((section) => (
            <SidebarButton
              key={section.id}
              icon={section.icon}
              title={section.title}
              isActive={activeSection === section.id}
              onClick={() => handleSectionClick(section.id)}
            />
          ))}
        </nav>
      </div>
  
      {/* Main Content */}
      <main className={`transition-all duration-300 md:ml-64 p-8 lg:p-12 ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        <div className="max-w-5xl mx-auto pt-16 md:pt-0">
          <div className="transition-opacity duration-300">
            {renderContent()}
          </div>
        </div>
      </main>
    </div>
  );
  
}
export default ResumeSite;